@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@tailwind base;

@tailwind components;

@tailwind utilities;


@font-face {
  font-family: Inter;
  src: url('assets/Inter.otf') format('opentype');
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Thin.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ThinItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ExtraBold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Black.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

html {
  font-family: Inter;
  font-feature-settings: 'tnum' off, 'lnum' on, 'zero' off, 'ss01' on;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}

@media (prefers-color-scheme: light) {
  html {
    color: #12141d;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color: white;
  }
}

.card-with-shadow {
  @apply bg-white shadow dark:bg-gray-900;
}

.tooltip-overrides {
  max-width: 300px !important;
  border-radius: 6px !important;
  text-align: left;
  @apply p-2 font-normal text-black dark:text-white text-sm bg-white shadow dark:bg-gray-900 border border-gray-600 dark:border-slate-800 !important;
}

@layer components {
  .card-with-shadow {
    @apply rounded shadow print:shadow-none;
  }

  .card-with-shadow-white-no-radius {
    @apply shadow bg-white dark:bg-zinc-900 print:bg-white print:shadow-none;
  }

  .card {
    @apply shadow bg-white dark:bg-zinc-900 print:bg-white print:shadow-none;
  }

  .card-no-radius {
    @apply bg-white dark:bg-zinc-900;
  }
}

.card-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  @apply bg-white dark:bg-zinc-900 border border-blue-500 !important;
}

.custom-ag-theme {
  --ag-background-color: #EFF8FF;
  --ag-row-hover-color: #ffffff;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: rgb(214, 218, 222);
  --ag-header-text-color: #ACB8C2;
  --ag-header-row-border-color: rgb(214, 218, 222);
  --ag-header-row-border-width: 1px;
  --ag-header-row-border-style: solid;
  .ag-header {
    border-top-color: #ACB8C2;
    border-bottom: 1px solid lightgray;
    color: #ACB8C2;
    font-weight: bolder;
  }
  .ag-row {
    text-align: left;
    padding-top: 24px;
  }
}

.inverse-custom-ag-theme {
  --ag-borders: none;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: rgb(214, 218, 222);
  --ag-cell-horizontal-border: #393939;
  --ag-background-color: #ffffff;
  --ag-row-hover-color: #EFF8FF;
  --ag-card-shadow: true;
  border-top: 1px solid #EFF8FF;
  .ag-header {
    border-top: 1px solid #EFF8FF;
  }
}

.divider::before{
  content: '';
  display: inline-block;
  width: 2px;
  height: 50%;
  background-color: #ACB8C2;
  margin-top: 20px;
}

.has-tooltip {
  @apply underline decoration-dotted cursor-help;
}

.alternating-row-even {
  @apply even:bg-gray-100 dark:even:bg-gray-900;
}

.alternating-row-odd {
  @apply odd:bg-gray-100 dark:odd:bg-gray-900;
}

.mat-icon {
  @apply align-middle;
}

.mat-optgroup-label {
  @apply sticky top-[42px] bg-white dark:bg-[#393939] z-10;
}

.color-transition {
  @apply transition-colors duration-150;
}

.unset .mat-expansion-panel-header.mat-expanded {
  @apply h-[unset] py-2 !important;
  font-family: inherit;
}

.unset .mat-expansion-panel-header {
  @apply h-[unset] py-2 !important;
  font-family: inherit;
}

.remove-expansion-panel-padding .mat-expansion-panel-body {
  @apply p-0 !important;
}

.wrong-version {
  bottom: 0;
  opacity: 1;
}

.version-warning {
  transition: bottom 1s;
  z-index: 100000;
  bottom: -40px;
  width: 100%;
  position: fixed;
  display: flex;
  min-width: 100vw;
  max-width: 100vw;
  margin: 0;
  border-radius: 0px;
  background-color: #feb300;
  align-items: center;
  opacity: 0;
}

.version-warning-text {
  justify-content: center;
  line-height: 24px;
  text-align: center;
  color: black;
  padding: 8px;
  width: 100%;
  font-size: 20px;
}

.no-padding-dialog .mdc-dialog__content {
  @apply p-0 overflow-hidden lg:max-h-[400px] bg-white dark:bg-zinc-900/75 backdrop-blur border border-gray-600 dark:border-slate-800 !important;
}

.no-padding-dialog .mat-dialog-container {
  @apply p-0 overflow-hidden lg:max-h-[400px] bg-white dark:bg-zinc-900/75 backdrop-blur border border-gray-600 dark:border-slate-800 !important;
}

.popup-clean .mapboxgl-popup-content {
  @apply p-0 rounded-none shadow-none bg-none font-inter text-base !important;
}

.sliced-gradient {
  background-image: linear-gradient(153deg, var(--tw-gradient-from) -8% 60%, 55%, var(--tw-gradient-to) 100%)
}

.stripe-gradient {
  background: repeating-linear-gradient(
    -45deg,
    var(--tw-gradient-from),
    var(--tw-gradient-from) 2px,
    var(--tw-gradient-to) 2px,
    var(--tw-gradient-to) 4px
  );
}

.mat-mdc-dialog-actions {
  @apply gap-x-2 justify-between !important;
}
